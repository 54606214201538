/* -------- FILTRO ---------- */
.filter-container {
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}

.close-container {
  background-color: rgba(0, 0, 0, 0.0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 50;
  width: calc(100vw - 700px);
  height: 100vh;
}

.menu-filtro {
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  position:fixed;
  top: 0px;
  right: 0px;
  width: 700px;
  max-width: 100vw;
  height: calc(100vh - 0px - 0px);
  padding-inline: 10px;
  z-index: 50;
}

.filter-header-container {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
}

.btn-close-filter {
  background-color: transparent;
  color: white;
  position: absolute;
  left: 5px;
  width: 38px;
  height: 38px;
  border-radius: 5px;
  border: none;
  z-index: 30;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-close-filter:hover {
  background-color: deeppink;
  color: white;
}

.menu-filtro .title {
  width: 100%;
  height: 38px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  padding-left: 5px;
}

.search-container {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
}

.input-search-card {
  background-color: #ffffff;
  width: 100%;
  height: 35px;
  min-height: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  border: none;
  color: rgb(70, 70, 70);
  padding-top: 1px;
  padding-left: 10px;
}

.input-search-card:focus {
  border: 1px solid dodgerblue;
}

.btns-set-periodo {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.btns-set-periodo .btn-periodo {
  width: 100%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  border-radius: 5px;
  border: none;
  background-color: rgb(134, 134, 134);
  color: white;
  font-weight: 700;
}

.btn-funcoes-save {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  border-radius: 5px;
  border: none;
  background-color: rgb(134, 134, 134);
  color: white;
  font-weight: 700;
}

.btn-funcoes-save:hover {
  background-color: deeppink;
}

.btn-funcoes {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  border-radius: 5px;
  border: none;
  background-color: rgb(134, 134, 134);
  color: white;
  font-weight: 700;
  margin-left: 2px;
}

.btn-funcoes:hover {
  background-color: deeppink;
}

.btns-set-periodo .btn-periodo:hover {
  background-color: deeppink;
}

/* -- select --- */
.select-periodo-filter-conteiner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding-inline: 5px;
}

.select-periodo-start {
  margin-right: 5px;
}

.input-date-filter{
  background-color: #ffffff;
  width: 100%;
  height: 35px;
  min-height: 35px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  padding-left: 10px;
  color: #6e6e6e;
  font-weight: 700;
  text-align: center;
}

.input-date-filter:focus {
  border: 2px solid dodgerblue;
}

.label-select-date {
  min-width: 28px;
  margin-left: 15px;
}

/*---- status ----*/
.btns-status-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.btn-status-vendido {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  border-radius: 5px;
  border: none;
  background-color:white;
  color: white;
  font-weight: 700;
}

.btn-status-vendido:hover {
  background-color: rgb(187, 187, 187);
}

.btn-status-perdido {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  border-radius: 5px;
  border: none;
  background-color: white;
  color: white;
  font-weight: 700;
  margin-inline: 3px;
}

.btn-status-perdido:hover {
  background-color: rgb(187, 187, 187);
}

.btn-status-arquivado {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  border-radius: 5px;
  border: none;
  background-color: white;
  color: white;
  font-weight: 700;
  margin-right: 3px;
}

.btn-status-arquivado:hover {
  background-color: rgb(187, 187, 187);
}

.btns-status-container .icon-status {
  font-size: 20px;
}

.title-item-filter {
  width: 100%;
  color: white;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.filter-participantes-container {
  width: 100%;
  padding-inline: 5px;
  height: calc(100vh - 515px);
  overflow-y: auto;
  overflow-x: hidden;
}

.filter-participantes-container::-webkit-scrollbar {
  width: 7px;
}

.filter-participantes-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}
