

.logout-container-logout{
  width:100vw;
  height: 100vh;
}

.logout-container {
  width: 100%;
    position: absolute;
    top: 40%;
    left: 70%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }

  .btn-login-in-logout {
    width: 80px;
    height: 32px;
    border: none;
    border-radius: 5px;
    background-color: deeppink;
    color: white;
    font-weight: 700;
  }
  
  .logout-form-container {
    width: 400px;
    height: 500px;
    border-radius: 10px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 30px 20px 10px 20px;
    background-color:rgba(116, 116, 116, 0.322);
    backdrop-filter: blur(2px);
    z-index: 1;
  }
  
  .logout-logo-container {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
  }
  
  .logout-logo-container img {
    height: 40px;
    vertical-align: middle; /* Elimina espaço abaixo da imagem */
    margin-right: 5px;
  }
  
  .logout-logo-container .logout-title {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 700;
  }
  
  .logout-logo-container .logout-title:hover{
    color: #2fb6ec;
  }
  
  .logout-form-group {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
  
  .logout-form-group input{
    background-color: #f5f5f5;
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid rgb(184, 184, 184);
    text-align: center;
    color: rgb(70, 70, 70);
    padding-left: 10px;
  }
  
  input::placeholder {
    text-align: left;
  }
  
  .logout-form-group input:focus {
    border: 1px solid #8411F6;
    outline: none;
  }
  
  .logout-container-login-form-btn {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
  }
  
  .logout-form-msg {
    height: 10px;
    color: red;
    margin-bottom: 20px;
  }
  
  .logout-form-btn {
    width: 100%;
    font-size: 15px;
    border: none;
    border-radius: 10px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
    background: #8411F6;
    font-weight: 700;
  }
  
  .logout-form-btn:hover {
    background-color: #a04af7;
  }
  
           
  .logout-form-btn {
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(168,86,255,1) 0%, rgba(0,212,255,1) 100%);
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;            
    border-radius: 10px;
    display: block;
  }
  
  .logout-form-btn:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }



  @media (max-width: 600px) {
    .logout-form-container {
      width: 95%;
    }
}
  
  
  