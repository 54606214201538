/* BOARD */
.board-container {
    background-color: white;
    max-width: 100vw;
    height: calc(100vh - 40px);
    display: flex;
    flex-wrap: wrap;
}

.board-header {
    width: 100vw;
    height: 35px;
    display: flex;
    align-items: center;
    padding-inline: 15px;
}

.board-header .title {
    font-size: 20px;
    font-weight: 700;
}

.board-header .title-order-by {
    font-size: 16px;
    font-weight: 700;
    margin-right: 5px;
}

.btn-order-by {
    border: none;
    width: 50px;
    height: 22px;
    border-radius: 5px;
    background-color: #9c9c9c;
    color: white;
    margin-right: 5px;
    font-size: 13px;
}

.btn-filter-float {
    background-color: rgba(255, 20, 145, 0.521);
    width: 55px;
    height: 55px;
    cursor: pointer;
    color: white;
    border-radius: 30px;
    position: fixed;
    right: 15px;
    bottom: 25px;
    border: none;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-filter-float:hover {
    background-color: dodgerblue;
    color: white;
}

.board-body {
    width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 75px);
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
}


@media (max-width: 1490px) {
    .board-body {
        justify-content: flex-start;
    }
}

.versao-system {
    position: fixed;
    bottom: 2px;
    left: 10px;
    font-size: 13px;
}

.icon-export-excel {
    min-width: 25px;
    min-height: 25px;
    font-size: 22px;
    border: none;
    background-color: transparent;
    color: rgb(14, 194, 53);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

/* --------------- EXPORTAÇÃO -------------*/

.icon-export-excel:hover {
    background-color: rgb(65, 65, 65);
    border-radius: 5px;
    color: white;
}

.icon-export-pdf {
    min-width: 25px;
    min-height: 25px;
    font-size: 22px;
    border: none;
    background-color: transparent;
    color: rgb(255, 75, 75);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
}

.icon-export-pdf:hover {
    background-color: rgb(65, 65, 65);
    border-radius: 5px;
    color: white;
}



/*-------- EM MANUTENÇÃO -----------*/

.modal-notification-manutencao {
    background-color: rgba(0, 0, 0, 0.363);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    font-size: 30px;
    font-weight: 700;
}

.notification-manutencao-container {
    background-color: white;
    width: 800px;
    max-width: 90vw;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

/* ----------- PCP ---------------------*/

.pcp-container {
    background-color: white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 10px 2px, rgba(0, 0, 0, 0.3) 0px 3px 7px 3px;
    width: calc(100vw - 10px);
    height: calc(100vh - 60px);
    border-radius: 10px;
    margin-left: 5px;
    z-index: 0;
    flex-wrap: wrap;
    padding: 5px;
    overflow: auto;
    margin-top: 5px;
    position: fixed;
    top: 40px;
}

.pcp-container::-webkit-scrollbar {
    width: 8px;
}

.pcp-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}


.pcp-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d1d1d1;
}

.pcp-table th,
.pcp-table td {
    border: 1px solid #d1d1d1;
    padding: 3px;
    text-align: center;
    /* Centralize o texto nas células */
}

/* Estilize as células do cabeçalho da tabela */
.pcp-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

/* Estilize as células de dados alternadas */
.pcp-table tr:nth-child(even) {
    background-color: #f5f5f5;
}

/* Estilize as células de dados quando passa o mouse sobre elas */
.pcp-table tr:hover {
    background-color: #e0e0e0;
    
}

.icon-grid-pcp {
    min-width: 35px;
    min-height: 35px;
    font-size: 22px;
    border: none;
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    position: fixed;
    right: 25px;
    bottom: 135px;
    background-color:deepskyblue;
    border-radius: 30px;
}

.icon-grid-pcp:hover {
    background-color: rgb(65, 65, 65);
    border-radius: 5px;
    color: white;
}

.pcp-header-container {
    min-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    margin-bottom: 8px;
    margin-left: 5px;
}

.container-buttons-header-controle {
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.label-header-controller {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 22px;
}


.buttons-header-controle {
    width: 100%;
    min-height: 30px;
    max-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

.buttons-controle {
    min-height: 30px;
    max-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;    
}

.icon-venda-efetuada-pcp {
    color:dodgerblue;
    width: 20px;
    height: 20px;
    margin-left: 0;
}

.icon-venda-verdida-pcp {
    color:deeppink;
    width: 20px;
    height: 20px;
}

.icon-card-arquivado-pcp {
    color:rgb(158, 158, 158);
    width: 20px;
    height: 20px;
}

.icon-card-entregue-pcp {
    color:#9862FF;
    width: 20px;
    height: 20px;
}

.icon-card-entregue-pcp {
    color:#9862FF;
    width: 20px;
    height: 20px;
}

.icon-card-ext-pcp {
    color: #ffc862;
    width: 20px;
    height: 20px;
}

.horas-totais-label {
    font-size: 12px;
    font-weight: 700;
}

.semana-container {
    background-color:rgba(51, 51, 51, 0.459);
    color: white;
}

.semana-label {
    font-size: 14px;
    font-weight: 700;
}   

.btn-programacao-producao {
    background-color:dodgerblue;
    color: white;
    height: 28px;
    border-radius: 5px;
    border: none;
    margin-left: 5px;
    padding-inline: 5px;
}

.header-semana-title {
    background-color: #9862FF;

}

.linha-vazia-programacao {
    background-color: white;
    min-height: 10px;
    display: flex;
}

.color-container-ref {
    display: flex;
    justify-content: center;
    align-items: center;
}

.color-ref {
    min-width: 20px;
    min-height: 20px;
    border-radius: 15px;
    display: flex;
    background-color: #9862FF;
    margin-right: 8px;
    border: 1px solid #333333;
    box-shadow: -0px 0px 10px rgba(2, 2, 2, 0.3);
}

.vidro-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.status-vidro {
    margin-right: 3px;
}

.icon-status-vidro-parcial {
    width: 20px;
    height: 20px;
    color: rgb(255, 217, 0);
}

.icon-status-vidro-comprar {
    width: 18px;
    height: 18px;
    color: rgb(245, 74, 74);
}

.icon-status-vidro-comprado {
    width: 20px;
    height: 20px;
    color:deepskyblue;
}

.icon-status-vidro-entergue {
    width: 20px;
    height: 20px;
    color: rgb(6, 180, 30);
}

.btn-select-board {
    height: 22px;
    background-color: #9862FF;
    color: white;
    border: none;
    border-radius: 5px;
    padding-inline: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px;
}

.icon-select-board {
    width: 22px;
    height: 22px;
    margin-left: 3px;
}

.select-board-container {
    display: flex;
    position: fixed;
    top: 75px;
    left: 8px;
    min-width: 280px;
    min-height: 200px;
    border-radius: 8px;
    background-color: #333333c7;
    backdrop-filter: blur(5px);
    z-index: 10;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
}

.lista-boards-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 30px;
    background-color: rgb(114, 114, 114);
    border-radius: 5px;
    margin-bottom: 5px;
    color: white;
}

.btn-tutoriais {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 21px;
    margin-top: 3px;
    background-color: transparent;
    border: none;
    color: #9c9c9c;
}

.btn-tutoriais:hover {
    color:deeppink;
}

.btn-order-by-date-update{
    width: 100px;
    height: 22px;
    border: none;
    border-radius: 5px;
    color: white;
    margin-right: 4px;
    background-color: #9c9c9c;
}


