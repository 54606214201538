/* COLUMN */
.column-container {
    background-color:#b1b1b146;
    backdrop-filter: blur(5px);
    min-width: 280px;
    max-width: 280px;
    min-height: 200px;
    max-height: calc(100% - 20px);
    border-radius: 8px;
    margin-inline: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    bottom: 0;
}

.column-header {
    width: 100%;
    min-height: 55px;
    max-height: 55px;
    display: flex;
    flex-wrap: wrap;     
}

.header-title-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 10px;
    min-height: 35px;
    max-height: 35px;
}

.header-title-container .title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    color: #364664;
    font-size: 16px;
}

.header-title-container .btn-add-card{
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border: none;
}

.header-title-container .btn-add-card:hover{
    color: deeppink;   
}

.column-header .column-infos-container {
    width: 100%;
    min-height: 20px;
    max-height: 20px;
    display: flex;
    justify-content:space-between;
    align-items: center;
    padding-inline: 15px;
}

.column-header .info {
    display: flex;
    font-size: 12px;
}

.column-body{
    width: 270px;
    height:100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 190px;
}


.column-body::-webkit-scrollbar {
    width: 7px;
}

.column-body::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.column-footer{
    width: 100%;
    min-height: 8px;
    max-height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 18px;
    font-size: 11px;
    color: #919191;
}

.loading {
    font-size: 18px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
}
