.grafico-container-j {
  width: 800px;
  max-width: 96vw;
  min-height: calc(100vh - 160px);
  display: flex;
  border-radius: 15px;
  background-color: rgba(219, 219, 219, 0.233);
  flex-direction: column;
  padding-top: 10px;
}

.grafico-jj {
  width: 800px;
  max-width: 96vw;
  min-height: calc(100vh - 160px);
}

@media screen and (max-width: 1100px) {}

.date-picker-container-participantes {
  max-width: 96%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.date-picker-container-participantes .title {
  display: flex;
  align-items: center;
  min-width: min-content;
  margin-right: 8px;
  margin-left: 8px;
}

.date-picker-container-participantes .picker {
  margin-right: 8px;
}

.date-picker-participantes {
  background-color: #ffffff;
  max-width: 130px;
  height: 40px;
  min-height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  padding-left: 10px;
  color: #6e6e6e;
  font-weight: 700;
  text-align: center;
}

.date-picker-participantes:focus {
  border: 2px solid dodgerblue;
}

.grafico-participantes {
  width: 800px;
  max-width: 96vw;
  min-height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.grafico-vazio-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.vazio-message {
  width: 100%;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.vazio-message-icon {
  width: 100%;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}