@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    text-decoration: none;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    background-color: white;
    color: #82839E;
    overflow: hidden;
    text-decoration: none;
}

ul {
    list-style: none;
}