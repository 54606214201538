.header-container {
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(168, 86, 255, 1) 0%, rgba(0, 212, 255, 1) 100%);
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    background-color: white;
    color: white;
}

.header-container .menu {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 200px;
}

.header-container .menu .fa-bars {
    margin-left: 20px;
    width: 17px;
    height: 17px;
    cursor: pointer;
}

.header-container .title {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: white;
}

.header-container .title:hover {
    color: rgb(235, 235, 235);
}

.header-container .title img {
    height: 30px;
}

.header-container .title h3 {
    margin-left: 10px;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
}

.avatar-container {
    background-color: white;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    padding: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    overflow: hidden;
    position: fixed;
    top: 3px;
    right: 8px;
    z-index: 10;
}

.avatar-container:hover {
    box-shadow: rgb(50, 195, 253) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}


.avatar-container-menu-user {
    background-color: white;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    padding: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    overflow: hidden;

}


.avatar-storage {
    width: 100%
}

.header-container .user {
    width: 200px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    margin-right: 10px;
}

.icon-full-screen {
    width: 35px;
    height: 35px;
    cursor: pointer;
    position: fixed;
    bottom: 90px;
    right: 25px;
    z-index: 1;
    background-color: rgba(8, 8, 8, 0.3);
    border-radius: 100px;
    padding: 3px;
}

.icon-full-screen:hover {
    background-color: deepskyblue;
}

.icon-notification-header {
    margin-left: 10px;
    margin-right: 70px;
    width: 18px;
    height: 18px;
    cursor: pointer;
}

.number-notification {
    background-color: rgb(255, 81, 116);
    color: white;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    right: 69px;
    top: 6px;
    font-size: 10px;
    cursor: pointer;

}

.notification-container-header {
    display: flex;
}

.number-notification-container {
    background-color: rgb(255, 81, 116);
    color: white;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    cursor: pointer;
    margin-left: 10px;
}

.user-icon-container {
    background-color: transparent;
    border-radius: 30px;
    margin-left: 17px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-container .user .user-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

/* -------- MODAL USER ----------------*/
.modal-user-container {
    width: 100%;
    height: 100%;
    background-color: rgba(2, 2, 2, 0.15);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
}

.header-container .modal-user {
    width: 350px;
    max-width: 97vw;
    border-bottom-left-radius: 15px;
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    background-color: #0b99f894;
    backdrop-filter: blur(5px);
    z-index: 50;
}

.modal-user .user-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.top-header-user {
    width: 100%;
    min-height: 30px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
}

.btn-close-menu-user {
    background-color: transparent;
    color: white;
    width: 38px;
    min-height: 38px;
    border-radius: 5px;
    border: none;
    position: absolute;
    left: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-close-menu-user .icon-close-user {
    width: 25px;
    height: 25px;
}

.btn-close-menu-user:hover {
    background-color: deeppink;
    color: white;
}

.modal-user .user-info .icon {
    min-width: 65px;
    min-height: 65px;
}

.user-info .email-user {
    min-height: 20px;
    color: white;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    margin-top: 15px;
}

.valor-vendas-user {
    font-size: 28px;
    font-weight: 700;
}

.body-menu_user-header {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.valor-total-equipe {
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.footer-menu-user-header {
    width: 100%;
    position: sticky;
    bottom: 25px;
    padding-inline: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-update-page {
    border: none;
    background-color: transparent;
    font-size: 30px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    color: white;
    border-radius: 5px;
    padding: 5px;
}

.btn-update-page:hover {
    background-color: deeppink;
}

.btn-sair {
    width: 80px;
    min-height: 40px;
    background-color: rgba(255, 20, 145, 0.99);
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.btn-sair .icon-sair {
    font-size: 30px;
}

.btn-sair:hover {
    background-color: rgba(0, 0, 0, 0.527);
}

.btn-sair .title {
    width: 40px;
    height: 40px;
    font-size: 14px;
    font-weight: 700;
}

/* ------- NAVBAR --------*/
.link {
    text-decoration: none;
}

.navbar-modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.header-container .navbar {
    position: fixed;
    top: 45px;
    left: 5px;
    width: 240px;
    border-radius: 8px;
    min-height: calc(100vh - 65px);
    background-color: #a45aff9c;
    backdrop-filter: blur(5px);
    padding: 8px;
    z-index: 11;
}

.header-container .navbar-title {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-container .btn-Dashboard {
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: rgba(0, 0, 0, 0.274);
    font-weight: 700;
    color: white;
}

.header-container .btn-Dashboard:hover {
    background-color: white;
    color: rgb(53, 53, 53);
}

/* ------- MENU NOTIFICATION --------*/
.modal-notification-container {
    width: calc(100vw - 400px);
    height: 100%;
    background-color: rgba(2, 2, 2, 0.15);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 20;
}

.header-container .modal-notification {
    width: 400px;
    max-width: 100vw;
    height: 100%;
    max-height: calc(100vh - 0px - 0px);
    position: fixed;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #0b99f894;
    backdrop-filter: blur(7px);
    z-index: 10;
}


@media screen and (max-width: 1200px) {
    .header-container .modal-notification {
        width: 430px;
    }
}


.modal-notification .notification-info {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.top-header-notification {
    width: 100%;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    padding-inline: 8px;
    padding-left: 20px;
    margin-bottom: 0px;
    font-weight: 700;
}

.btn-close-menu-notification {
    background-color: transparent;
    color: white;
    width: 38px;
    height: 38px;
    border-radius: 5px;
    border: none;
    position: absolute;
    left: 5px;
    top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-close-menu-notification:hover {
    background-color: deeppink;
    color: white;
}

.icon-close-notification {
    width: 25px;
    height: 25px;
}

.notification-header {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto;
    padding-inline: 5px;
}

.notification-header::-webkit-scrollbar {
    width: 5px;
}

.notification-header::-webkit-scrollbar-thumb {
    background-color: #eeeeee;
    border-radius: 5px;
}


.lista-prev-fechamento-container {
    width: 100%;
}

.lista-tarefas-container {
    width: 100%;
}

.notification-header .notification-title-header {
    width: 100%;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-bottom: 8px;
    font-size: 16px;
}

.notification-item-previsao {
    background-color: rgba(3, 3, 3, 0.4);
    width: calc(100% - 0px);
    margin-left: 0px;
    min-height: 160px;
    max-height: 160px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: white;
}

.notification-item-tarefa {
    background-color: rgba(3, 3, 3, 0.4);
    width: calc(100% - 0px);
    margin-left: 0px;
    min-height: 160px;
    max-height: 160px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: white;
}

.previsao-item-name {
    font-size: 14px;
    width: 100%;
    min-height: 20px;
    display: flex;
    text-align: center;
    font-weight: 700;
    align-items: center;
    max-height: 25px;
}

.mensagem-sem-notification {
    font-size: 14px;
    width: 100%;
    min-height: 20px;
    display: flex;
    text-align: center;
    font-weight: 700;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.notification-item-name-header {
    font-size: 14px;
    width: 100%;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}


.notification-item-previsao:hover {
    background-color: rgba(94, 94, 94, 0.4);
}

.notification-item-tarefa:hover {
    background-color: rgba(94, 94, 94, 0.4);
}




.icon-tarefas-item {
    width: 20px;
    height: 25px;
    margin-right: 5px;
    margin-left: 5px;
}

/* ProgressBar.css */

.title-meta {
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    margin-top: 20px;
}

.progress-bar-container {
    width: calc(100% - 15px);
    min-height: 30px;
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.progress-bar {
    min-height: 26px;
    background: linear-gradient(50deg, rgba(2, 0, 36, 1) 0%, rgb(255, 86, 170) 0%, rgba(0, 212, 255, 1) 70%, rgb(43, 255, 0) 100%);
    margin-left: 2px;
    margin-right: 2px;
    border-radius: 5px;
    transition: width 3.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-label {
    width: 60px;
    height: 18px;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: rgb(255, 255, 255);
    position: fixed;
    left: calc(50% - 30px);
    background-color: rgba(0, 0, 0, 0.267);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}


/* ------- SINO DE VENDA ---- */
.notification-admin {
    position: absolute;
    top: calc((100vh / 2) - 300px);
    left: calc((100vw / 2) - 150px);
    width: 300px;
    z-index: 100;
    display: flex;
    align-items: center;
    border-radius: 10px;
    flex-direction: column;
    padding: 10px;
    background-color: rgba(2, 2, 2, 0.466);
}

.header-notification-adm {
    width: 100%;
    height: 55px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 700;
}

.notification-admin-icon-sino {
    color: gold;
    width: 120px;
    height: 120px;
    filter: drop-shadow(3px 3px 7px rgba(255, 230, 0, 0.829));
}

.notification-admin-icon-sino {
    display: inline-block;
    position: relative;
    animation: bell-animation 0.3s infinite alternate;
}

@keyframes bell-animation {
    0% {
        transform: rotate(-15deg);
    }

    100% {
        transform: rotate(15deg);
    }
}



.novas-vendas-container {
    width: 100%;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.nova-venda-name {
    min-height: 24px;
    font-size: 20px;
    color: white;
}

.nova-venda-valor {
    min-height: 30px;
    font-size: 28px;
    color: white;
    margin-bottom: 10px;
}

.nova-venda-adm {
    display: flex;
    flex-direction: column;
    align-items: center;
}


/*----------- CALENDARIO -----*/
/* Calendar.css */
.calendar-container {
    position: absolute;
    top: 45px;
    left: 5px;
    width: calc(100vw - 10px);
    height: calc(100% - 55px);
    margin: 0 auto;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    z-index: 5;
    overflow: auto;
    /* Adicione uma barra de rolagem quando o conteúdo ultrapassar a altura máxima */

}


/* Estilo para os números dos dias */
.fc-daygrid-day-number {
    font-size: 14px;
    font-weight: bold;
    color: #222222;
    /* Cor do número dos dias */
}

/* Estilo para a data no cabeçalho */
.fc-toolbar-title {
    font-size: 18px;
    font-weight: bold;
    color: #353535;
    /* Cor da data no cabeçalho */
}

.fc-event-title {
    color: #ffffff;
    /* Defina a cor do texto desejada, por exemplo, branco (#FFFFFF) */
    font-size: 10px;
}

/* Defina um fundo preto semi-transparente para o calendário */
.fc-view {
    background-color: white;
}

/* Defina a cor da grade como cinza */
.fc-dayGrid-view .fc-day {
    background-color: #333;
}

/* Defina a cor do texto como branco */
.fc-dayGrid-view .fc-day,
.fc-dayGrid-view .fc-day-number {
    color: rgb(63, 63, 63);
}

/* Estilize os botões do calendário como vermelhos */
.fc-button-primary {
    background-color: red;
    border-color: red;
}

.icon-calendar {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

/* Estilo para as células do calendário */
.fc-daygrid-event {
    height: 30px !important;
    /* Altura desejada das células em pixels */
    font-size: 12px;
    /* Tamanho da fonte desejado */
    background-color: deepskyblue;
    /* Cor de fundo desejada */
    color: white;
    /* Cor do texto desejada */
    border-radius: 8px;

}

/* Estilo para as células do calendário (cor de fundo) */
.fc-daygrid-day.fc-day-today {
    background-color: red;
    /* Cor de fundo para o dia atual */
}

/* Estilo para as células do dia */
.fc-daygrid-day.fc-day {
    height: 40px !important;
    /* Altura desejada das células em pixels */
    font-size: 14px;
    /* Tamanho da fonte desejado */
    color: white;
    /* Cor do texto desejada */
    border-radius: 8px;
}

.close-calendario {
    background-color: deeppink;
    color: white;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 30px;
    position: fixed;
    top: 50;
    font-size: 16px;
}

/*----------- LGPD ----------------*/

.container-lgpd-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    background-color: #333333b4;
}

.container-lgpd {
    border: 1px solid #ccc;
    padding: 10px;
    color: #222222;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 500px;
    max-width: 98%;
    position: fixed;
    top: 50px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.container-lgpd-title {
    color: #494949;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-lgpd-ul {
    margin-top: 5px;
}

.container-lgpd-li-h2-p {
    color: #494949;
    margin-bottom: 15px;
}

.container-lgpd-agradecimento {
    color: #494949;
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 14px;
}

.btn-lgpd {
    position: fixed;
    bottom: 25px;
    left: 10px;
    width: 55px;
    height: 55px;
    border: none;
    border-radius: 30px;
    z-index: 5;
    background-color: #9211fc7c;
    color: white;
}

.btn-lgpd:hover {
    background-color: rgb(255, 0, 119);
    color: white;
}


.label-info-lgpd {
    font-weight: 700;
    font-size: 12px;
}



/*--------- trofeu --------------*/
.trofeu-container {
    position: fixed;
    left: 10px;
    bottom: 100px;
    z-index: 5;
    background-color: rgb(255, 64, 112);
    border-radius: 100px;
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(247, 247, 247);
    font-size: 35px;
    padding-bottom: 5px;
}

.trofeu-container:hover {

    background-color:deepskyblue;
}

.trofeu-img {
    height: 50px;
}

.ganhadores-container {
    position: fixed;
    left: 10px;
    top: 45px;
    z-index: 90;
    background-color: #0a0a0aaf;
    border-radius: 20px;
    width: calc(100vw - 20px);
    height: calc(100vh - 65px);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.ganhadores-trofeu-img {
    height: 200px;
}

.ganhador-trofeu-img {
    max-width: calc(100%);
    max-height: calc(100% - 30px);
    height: auto;
    border-radius: 20px;
}

.column-ganhadores {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc( ( 100vw - 50px ) / 2) ;
    height: calc((100vh - 165px) / 2);
    margin: 2px;
}

.ano-vencedor{
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 16px;
}

.title-ganhadores{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 26px;
    min-height: 30px;
}

.container-ganhadores-ano {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.close-trofeu-container{
    position: fixed;
    z-index: 100;
    background-color: rgb(248, 248, 248);
    border-radius: 100px;
    padding: 10px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 55px;
    right: 20px;
    font-size: 18px;
    color: rgb(250, 38, 120);
}

.close-trofeu-container:hover{
    background-color:deeppink;
    color: white;
}