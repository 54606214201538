
/* BOARD */
.users-container {
    width: 100vw;
    height: calc(100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.users-header {
    width: 100vw;
    height: 0px;
    display: flex;
    padding-inline: 10px;
    margin-top: 0px;
}

.users-header .title {
    font-size: 22px;
    font-weight: 700;
}

.btn-create-user {
    padding-inline: 3px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 15px;
    border: none;
    background-color: deeppink;
    border-radius: 30px;
    color: white;
    font-size: 35px;
    position: fixed;
    right: 15px;
    bottom: 25px;
    z-index: 8;
}

.btn-create-user:hover {
    background-color: dodgerblue;
}

.users-body {
    width: 100vw;
    height: calc(100vh - 180px);
}

.lista-users-container {
    width: 100vw;
    height:100%;
}

.users-body .lista-users {
    width: 100vw;
    max-height: calc(100vh - 220px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    justify-content: center;
}

.users-body .item-user {
    background-color:#b1b1b146;
    backdrop-filter: blur(5px);
    width: 900px;
    max-width: 99vw;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin: 5px;
    text-align: center;
}

.users-body .item-user:hover {
    background-color:dodgerblue;
    color: white;
}

.header-lista {
    padding-inline: 20px;
    height: 0px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.total-users-label-adm {
    width: 900px;
    max-width: 99vw;
    height: 20px;
    display: flex;
    margin-left: 20px;
    color: deeppink;
}