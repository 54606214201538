/* BOARD */
.dashboard-container {
    background-color: white;
    width: 100vw;
    height: calc(100vh - 40px);
    display: flex;
    flex-wrap: wrap;
}

.dashboard-header {
    width: 100vw;
    height: 35px;
    display: flex;
    align-items: center;
    padding-inline: 20px;
}

.dashboard-header .title {
    font-size: 20px;
    font-weight: 700;
}

.dashboard-body {
    width: 100vw;
    height: calc(100vh - 75px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
}

.charts-dashboard-container {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.btn-filter-float-dashboard {
    background-color: deeppink;
    width: 55px;
    height: 55px;
    cursor: pointer;
    color:white;
    border-radius: 30px;
    position: fixed;
    right: 15px;
    bottom: 25px;
    border: none;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-filter-float-dashboard:hover {
    background-color:dodgerblue;
    color:white;
}

.btn-chart-cidade {
    width: 110px;
    min-height: 22px;
    background-color: dodgerblue;
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    color: white;
  }
  
  .btn-chart-cidade:hover {
    background-color:deeppink;
  }


