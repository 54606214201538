.historico-container {
  background-color: #dfdfdf;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-historico {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-historico .title-historico {
  padding-inline: 10px;
  font-size: 18px;
  font-weight: 700;
}

.icon-open-close{
  font-size: 30px;
  background-color: #B3B3B3;
  border-radius: 5px;
  color: #f3f3f3;
}

.icon-open-close:hover{
  background-color:deepskyblue;
}

.body-historico {
  width: 100%;
}

.footer-historico {
  width: 100%;

}

.input-edit-date-historico {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.input-edit-date-historico:focus {
  border: 2px solid dodgerblue;
}

.list-button-historico {
  width: 100%;
}



.btn-add-historico-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.btn-add-historico {
  width:100px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: dodgerblue;
}

.btn-add-historico:hover {
  background-color:deeppink;
}

.item-historico {
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  margin-bottom: 3px;
  border-radius: 5px;
  padding: 3px;
}

.descricao-historico {
  width: 100%;
  min-height: 20px;
  display: flex;
  align-items: center;
  overflow-wrap: break-word;
  word-wrap: break-word; /* Fallback para navegadores antigos */
  max-width: 100%; /* Define a largura máxima */
  font-size: 13px;
  margin-inline: 5px;
}

.date-historico {
  font-size: 12px;
  margin-right: 5px;
}

.container-rigth-historico {
  height: 30px;
  display: flex;
  align-items: center;

}

.btn-del-historico {
  background-color: rgb(110, 110, 110);
  color: white;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  margin-left: 10px;
  display: none;
}

.btn-del-historico:hover {
  background-color: deeppink;
}


