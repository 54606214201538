.modal-create-card-container {
  width: 100vw;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-card-container {
  background-color: white;
  width: 700px;
  max-width: 96%;
  max-height: calc(100% - 75px);
  border-radius: 10px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 45px;
}

.create-card-header {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  display: flex;
}

.create-card-header .title {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 20px;
  font-weight: 700;
}

.create-card-header .btn-close-container {
  min-height: 50px;
  max-height: 50px;
  border-radius: 30px;
  display: flex;
  align-items: flex-start;
  padding: 5px;
}

.create-card-header .btn-close {
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 30px;
  border: none;
  font-size: 14px;
}

.create-card-header .btn-close:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}

.create-card-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.create-card-body .title {
  width: 100%;
  min-height: 22px;
  display: flex;
  flex-direction: column;
  margin-inline: 5px;
}

.create-card-body .input-create-card {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.create-card-body .input-create-card:focus {
  border: 2px solid dodgerblue;
}



.info-container {
 height: 25px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: rgb(253, 82, 82);
}

.create-card-footer {
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
  margin-bottom: 10px;
  padding-inline: 10px;
  position: sticky;
  bottom: 0;
}

.create-card-footer .btn-cancel {
  background-color: rgb(179, 179, 179);
  width: 100px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.create-card-footer .btn-cancel:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}

.create-card-footer .btn-save {
  background-color: dodgerblue;
  width: 100px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  color: white;
  font-weight: 700;
}

.create-card-footer .btn-save:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}


/* --- SELECT ---- */

.create-card-select {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: #757575;
  padding-left: 10px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.card-select {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  color: #757575;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  border: 2px solid dodgerblue;
}

.card-select .item-list-select {
  width: 100%;
  min-height: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card-select .item-list-select:hover {
  background-color: #B3B3B3;
  border-radius: 5px;
  color: white;
}

.card-stars-container {
  margin-bottom: 8px;
}



.icon-star-create-card {
  width: 26px;
  height: 26px;
  margin-inline: 3px;
  color: rgba(172, 172, 172, 0.952);
}

.etiqueta-container {
  width: 100%;
  margin-bottom: 10px;
}

.etiqueta-container .btn-etiqueta {
  border: none;
  margin-bottom: 4px;
  margin-inline: 5px;
  padding-inline: 5px;
  border-radius: 5px;
  height: 25px;
  color: #757575;
}