/* CARD */
.card-container {
    background-color: white;
    max-width: 100%;
    height: max-content;
    margin-inline: 3px;
    margin-bottom: 8px;
    border-radius: 8px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    position: relative;
}

.card-container:hover {
    background-color: rgba(221, 221, 221, 0.075);
    box-shadow: rgba(255, 1, 192, 0.452) 0px 4px 10px -2px, rgba(255, 0, 212, 0.349) 2px 5px 7px -3px;
}

.card-header {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-header .title {
    font-size: 13px;
    font-weight: 700;
    margin-top: 2px;
}

.card-header .icon {
    font-size: 16px;
}


.card-body {
    width: 100%;
    min-height: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 13px;
}

.card-body .card-name {
    padding-inline: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 22px;
}

.card-body .card-name .valor-card {
    font-weight: 700;
    font-size: 15px;
}


.card-name .etiqueta {
    padding-right: 5px;
    display: flex;
    align-items: center;
    min-height: 19px;
    border-radius: 3px;
    font-size: 12px;
}


.div-color-etiqueta {
    width: 6px;
    height: 19px;
    display: flex;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    margin-right: 3px;
}


.card-body .icon {
    margin-right: 5px;
    width: 19px;
    height: 19px;
}

.card-footer {
    width: 100%;
    min-height: 25px;
    max-height: 25px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-inline: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 2px;
}

.current-date-card {
    font-size: 10px;
    margin-left: 7px;
    display: flex;
    align-items: center;
    height: 100%;
}

.star-container {
    display: flex;
    width: 100%;
    margin-left: 5px;
}

.icon-star {
    width: 18px;
    height: 18px;
    margin-inline: 2px;
    color: rgba(172, 172, 172, 0.952);
}

.container-icons-status {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-venda-efetuada-card {
    color: dodgerblue;
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.icon-venda-verdida-card {
    color: deeppink;
    width: 23px;
    height: 23px;
    margin-right: 5px;
    display: flex;
}

.icon-card-arquivado {
    color: rgb(158, 158, 158);
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: flex;
}

.icon-card-entregue {
    color: #9862FF;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: flex;
}

.icon-card-ext {
    color: #ffc862;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: flex;
}

.icon-card-etapa-producao {
    color: deeppink;
    width: 21px;
    height: 21px;
    margin-right: 5px;
    display: flex;
}

.icon-card-prioridade {
    color: deeppink;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    display: flex;
}

.icon-card-ancora {
    color: deeppink;
    width: 22px;
    height: 22px;
    margin-right: 5px;
    display: flex;   
}

/* ------------ CONTADOR DE DIAS --------------*/
.day-counter-container {
    min-width: 45px;
    min-height: 19px;
    border-radius: 5px;
    background-color: deeppink;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.label-day-counter {
    font-size: 10px;
    color: white;
}

.prioridade-label {
    width: calc(100% - 20px);
    margin-left: 10px;
    min-height: 10px;
}

.prioridade-container {
    width: calc(100% - 20px);
    min-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: #ffffff;
    margin-left: 10px;
    font-size: 14px;
    background-color: deepskyblue;
}