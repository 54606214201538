/* BOARD */
.adm-container {
    background-color: white;
    max-width: 100vw;
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
}

.adm-header {
    width: 100vw;
    min-height: 35px;
    display: flex;
    align-items: center;
    padding-inline: 20px;
}

.adm-header .title {
    font-size: 20px;
    font-weight: 700;
}

.menu-superior-adm {
    width: 100%;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding-inline: 10px;
}

.btns-adm {
    padding-inline: 5px;
    width: 90px;
    min-height: 40px;
    border-radius: 8px;
    border: none;
    color: white;
    background-color:dodgerblue;
    margin-right: 8px;
}

.btns-adm:hover {
    background-color:rgb(116, 116, 116);
}

.adm-body {
    width: 100vw;
    max-width: 100vw;
    height: calc(100%);
    display: flex;
    justify-content: center;
    overflow-x: auto;
    overflow-y: hidden;
}

@media (max-width: 1100px) {
    .adm-body {
        justify-content: flex-start;
    }
}


.adm-body .etiquetas-container {
    width: 400px;
    height: calc(100vh - 120px);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-inline: 10px;
    border-radius: 10px;
    background-color: #b1b1b146;
    backdrop-filter: blur(5px);
}

.adm-body .header-etiquetas {
    width: 100vw;
    min-height: 50px;
    max-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.adm-body .header-etiquetas .title {
    font-size: 18px;
    font-weight: 700;
}

.body-etiquetas-adm {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 8px;
    overflow-y: auto;
}

li {
    list-style: none;
}

.title-etiquetas{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 25px;
}

.body-etiquetas-adm .item-etiqueta {
    background-color: white;
    border-radius: 5px;
    width: 100%;
    height: 30px;
    font-size: 14px;
    font-weight: 700;
    padding-inline: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.nova-etiqueta {
    height: 25px;
    border-radius: 5px;
    border: none;
    color: white;
    background-color: dodgerblue;
    padding-inline: 8px;
    margin-left: 15px;
}

.nova-etiqueta:hover {
    background-color:deeppink;
}

.icons-etiqueta-container {
    display: flex;
    align-items: center;
}

.body-etiquetas-adm .item-etiqueta .icon{
    width: 18px;
    height: 18px;
    margin-left: 5px;
}

.body-etiquetas-adm .item-etiqueta .icon:hover{
    color: deeppink;
}

.footer-etiquetas{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-inline: 10px;

}

.footer-etiquetas .btn-save{
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: none;
    background-color: dodgerblue;
    color: white;
    padding-inline: 8px;
}

.footer-etiquetas .btn-save:hover{
    background-color: deeppink;
}

