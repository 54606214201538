.grafico-container-e {
  width: calc((  100vw - 0px ) / 2.55 );
  min-width: calc((  100vw - 0px ) / 2.55 );
  max-width: 95vw;
  height: calc( ( 100vh - 82px ) );
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(219, 219, 219, 0.233);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.grafico-ee {
  width: calc((  100vw - 0px ) / 2.55 );
  min-width: calc((  100vw - 0px ) / 2.55 );
  max-width: 95vw;
  height: calc( ( 100vh - 82px ) );
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

}

@media screen and (max-width: 1100px) {
  .grafico-container-e {
    width: 95vw;
  }
  
  .grafico-ee {
    width: 95vw;
  }
}

