.tarefas-container {
  background-color: #dfdfdf;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.header-tarefas {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-tarefas .title-tarefas {
  padding-inline: 10px;
  font-size: 18px;
  font-weight: 700;
}

.body-tarefas {
  width: 100%;
}

.footer-tarefas {
  width: 100%;

}

.date-taefas-container {
  width: 100%;
}

.input-edit-date-tarefa {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.input-edit-date-tarefa:focus {
  border: 2px solid dodgerblue;
}

/* --- SELECT TAREFA --- */

.select-tarefa-container {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  color: #757575;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  border: 2px solid dodgerblue;
}


.list-button-tarefas {
  width: 100%;
}

.btn-seletc-tarefa{ 
  width: 100%;
  border: none;
  margin-bottom: 2px;
  margin-top: 2px;
  margin-inline: 5px;
  padding-inline: 5px;
  border-radius: 5px;
  min-height: 25px;
  color: #757575;
}

.btn-seletc-tarefa:hover{
  background-color: dodgerblue;
  color: white;
}

.btn-add-tarefa-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.btn-add-tarefa {
  width:100px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 35px;
  border-radius: 5px;
  border: none;
  color: white;
  background-color: dodgerblue;
}

.btn-add-tarefa:hover {
  background-color:deeppink;
}

.item-tarefa {
  width: 100%;
  min-height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f3f3f3;
  margin-bottom: 3px;
  border-radius: 5px;
  padding-inline: 8px;
  font-size: 13px;
}

.container-rigth-tarefas {
  height: 30px;
  display: flex;
  align-items: center;
}

.icon-tarefa-finalizada {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.icon-tarefa-finalizada:hover {
  color:deeppink;
}

.btn-del-tarefa {
  background-color: rgb(110, 110, 110);
  color: white;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 10px;
  font-size: 12px;
  margin-left: 10px;
  display: none;
}

.btn-del-tarefa:hover {
  background-color: deeppink;
}


