.modal-edit-orcamento-container {
  width: 100vw;
  height: calc(100vh - 55px);
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  left: 0;
  top: 55px;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: blue;
  z-index: 9;
}

.body-edit-orcamento {
  width: 100%;
  height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: auto;
}

.select-product-container {
  background-color: white;
  width: 600px;
  display: flex;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  position: fixed;
  top: 80px;
  flex-direction: column;
  max-height: calc(100vh - 100px);
}

.item-lista-produtos {
  width: calc(100% - 20px);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  background-color: deepskyblue;
  color: white;
  margin-inline: 10px;
  margin-bottom: 5px;
}

.btn-add-product {
  border: none;
  background-color: deeppink;
  color: white;
  width: 55px;
  height: 55px;
  border-radius: 30px;
  margin-left: 10px;
  font-size: 25px;
  position: fixed;
  bottom: 25px;
  right: 20px;
}

.btn-add-product:hover {
  background-color: deepskyblue;
}

.add-product-lista {
  width: 80px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: deepskyblue;
  color: white;
  margin-left: 10px;
}

.add-product-lista:hover {
  background-color: dodgerblue;
  color: white;

}

.cancel-product-lista {
  width: 80px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: rgb(126, 126, 126);
  color: white;
}

.cancel-product-lista:hover {
  background-color: dodgerblue;
  color: white;

}


.delet-product-lista {
  width: 80px;
  height: 35px;
  border: none;
  border-radius: 5px;
  background-color: deeppink;
  color: white;
  margin-right: auto;
}

.delet-product-lista:hover {
  background-color: dodgerblue;
  color: white;

}

.body-select-produto {
  width: 100%;
  max-height: 400px;
  overflow-y: auto;
}

.btns-select-produtos-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.busca-produto-container {
  width: 100%;
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.item-lista-produtos-select {
  width: calc(100% - 20px);
  height: 35px;
  display: flex;
  align-items: center;
  justify-content:space-between;
  border-radius: 5px;
  background-color: rgb(182, 182, 182);
  color: white;
  margin-inline: 10px;
  margin-bottom: 5px;
}

.label-item-select-product {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5px;
}

.label-item-select-product-codigo {
  width: 140px;
  height: 35px;
  border-radius: 5px;
  background-color: rgb(71, 71, 71);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 5px;
}

.product-table {
  width: calc(100% - 20px);
  color: rgb(83, 83, 83);
  margin-left: 10px;
  margin-top: 10px;
}

.header-table-orcamento {
  height: 40px;

  background-color: rgb(0, 189, 247);
  color: white;
  border-radius: 5px;
}

.item-lista-orcamento {
  border-radius: 5px;
  height: 40px;
  border: 1px solid rgb(151, 151, 151);
  text-align: center;
  padding-inline: 3px;
}

.tabela-lista-orcamento {
  width: 100%;
}

.tabela-lista-orcamento:hover {
  background-color: rgb(158, 158, 158);
  color: white;
}

.valor-total-produtos-orcamento {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: rgb(63, 63, 63);
  background-color: rgb(255, 0, 128);
  margin-left: 10px;
  padding: 5px;

  border-radius: 5px;
  color: white;
  font-weight: 700;
}

.peso-total-produtos-orcamento {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: rgb(63, 63, 63);
  background-color: rgb(110, 110, 110);
  margin-left: 10px;
  padding: 5px;

  border-radius: 5px;
  color: white;
  font-weight: 700;
}


.total-orcamento {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 10px;
}

.header-orcamento-header {
  width: calc(100% - 10px);
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.dados-orcamento-container {
  width: 100%;
  display: flex;
}

.create-orcamento-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 10px;
}

.create-orcamento-body .title {
  width: 100%;
  min-height: 22px;
  display: flex;
  flex-direction: column;
  margin-inline: 5px;
  color: rgb(94, 94, 94);
}



.create-orcamento-body .input-orcamento-obs-textarea {
  background-color: #ffffff;
  width: 100%;
  height: 60px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 4px;
  padding-top: 5px;
}

.create-orcamento-body .input-orcamento-obs-textarea:focus {
  border: 2px solid dodgerblue;
}

.create-orcamento-body .input-orcamento-card {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.create-orcamento-body .input-orcamento-card:focus {
  border: 2px solid dodgerblue;
}

.btns-header-orcamento-header {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  color: rgb(70, 70, 70);

  margin-bottom: 10px;
}


.status-orcamentos-container {
  width: 200px;
  height: 35px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  justify-content: flex-end;
}

.status-orcamentos-container-date {
  width: 200px;
  height: 35px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}



.icon-export-excel-orcamento {
  min-width: 35px;
  min-height: 35px;
  font-size: 30px;
  border: none;
  background-color: transparent;
  color: rgb(14, 194, 53);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}


.icon-export-excel-orcamento:hover {
  background-color: rgb(65, 65, 65);
  border-radius: 5px;
  color: white;
}

.icon-export-pdf-orcamento {
  min-width: 35px;
  min-height: 35px;
  font-size: 30px;
  border: none;
  background-color: transparent;
  color: rgb(255, 75, 75);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}

.icon-export-pdf-orcamento:hover {
  background-color: rgb(65, 65, 65);
  border-radius: 5px;
  color: white;
}


.icon-save-orcamento {
  min-width: 50px;
  min-height: 50px;
  font-size: 40px;
  border: none;
  background-color: transparent;
  color: rgb(105, 105, 105);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}


.icon-save-orcamento:hover {
  background-color: rgb(65, 65, 65);
  border-radius: 5px;
  color: white;
}

.numero-orcamento-label {
  min-width: 100px;
  height: 30px;
  font-size: 20px;
  font-weight: 700;
  color: deeppink;
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-orcamento-label {

  height: 30px;
  font-size: 14px;
  font-weight: 700;
  color: rgb(90, 90, 90);
  display: flex;
  align-items: center;
  padding-top: 2px;
  margin-left: 5px;
  justify-content: flex-end;
}

.icon-pedido-orcamento {
  min-width: 35px;
  min-height: 35px;
  font-size: 30px;
  border: none;
  background-color: transparent;
  color: rgb(105, 105, 105);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}


.icon-pedido-orcamento:hover {
  background-color: rgb(65, 65, 65);
  border-radius: 5px;
  color: white;
}

.btns-status-orcamento-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn-ocultar-cabecalho {
  height: 40px;
  border: none;
  background-color: rgb(164, 53, 255);
  color: white;
  border-radius: 5px;
  font-weight: 700;
  font-size: 14px;
  padding-inline: 5px;
  margin-right: auto;
  margin-left: 0px;
}

.btn-ocultar-cabecalho:hover {

  background-color: deeppink;
  color: white;

}

.ferramentas-container {
  width: calc(100% - 20px);
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-inline: 10px;
  padding: 5px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-top: 10px;
}

.container-name-cliente-infos-iser {
  display: flex;
  align-items: center;
}

.icon-user-orcamento {
  background-color: #B3B3B3;
  min-width: 38px;
  min-height: 38px;
  font-size: 30px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 5px;
  border-radius: 5px;
  color: white;
}


.icon-user-orcamento:hover {
  background-color: deeppink;
  color: white;
}




/*-----------VIEW PDF -------------*/

.modal-pdf-orcamento {

  background-color: #36363681;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
}

.close-pdf-orcamento {
  width: 40px;
  height: 40px;
  background-color: deeppink;
  color: white;
  border-radius: 20px;
  border: none;
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 100;
}

.close-pdf-orcamento:hover {
  background-color:dodgerblue;
}


.btn-pdf-orcamento {
  width: 40px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  color: rgb(255, 91, 91);
  border-radius: 20px;
  border: none;
  position: fixed;
  top: 10px;
  left: 60px;
  z-index: 100;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-pdf-orcamento:hover {
  background-color:rgb(201, 201, 201);
}

.container-pdf {
  width: 200mm;
  /* 210mm * 2.83465 */
  /* 297mm * 2.83465 */
  background-color: white;
  border: 0px solid rgb(192, 192, 192);
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  overflow-y: auto;
}

.header-container-pdf-orcamento {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
}

.avatar-storage-orcamento {
  margin-left: 10px;
  width: 100px;
  height: auto;
  object-fit: contain;
}

.title-pdf-orcamento {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: deepskyblue;
  font-size: 28px;
  font-weight: 700;
  margin-left: 20px;
  color: white;
}

.document-pdf-orcamento {
  height: 60px;
  min-width: max-content;
  padding-inline: 15px;
  display: flex;
  align-items: center;
  background-color: rgb(119, 119, 119);
  color: white;
  font-size: 24px;
  font-weight: 700;
  flex-direction: column;
  justify-content: center;
  padding-top: 11px;
}

.info-container-pdf-orcamento {

  width: calc(100% - 4px);
  height: 100px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  margin-left: 2px;

}

.left-infos-orcamento-container {
  height: 100px;
  display: flex;
  width: 60%;
  flex-direction: column;
}

.title-info-orcamento {
  color: #363636;
  font-weight: 700;
  font-size: 14px;
}

.info-value-title-orcamento {
  color: #949494;
  font-size: 12px;
  margin-left: 8px;
}

.right-infos-orcamento-container {
  height: 100px;
  display: flex;
  width: 40%;
  flex-direction: column;
}



.titel-observacoes-pdf-orcamento {
  color: #363636;
  padding: 2px;
  margin-left: 3px;
  font-weight: 700;
}

.obs-container-pdf-orcamento {
  width: calc(100% - 4px);
  height: 76px;
  min-height: 76px;
  max-height: 76px;
  display: flex;
  border: 1px solid rgb(117, 117, 117);
  flex-direction: column;
  font-size: 12px;
  padding: 2px;
  border-radius: 8px;
  margin-left: 2px;
}

.descricao-observacoes-pdf-orcamento {
  color: #363636;
  display: flex;
  align-items: center;
  padding: 5px;
  white-space: pre-line;
  box-sizing: border-box;
  font-size: 10px;
  margin-bottom: 1px;
}

.valores-container-pdf-orcamento-frete {
  width: 100%;
  min-height: 10px;
  display: flex;
  font-size: 10px;
  justify-content: flex-end;
  align-items: center;
  margin-top: 7px;
}

.valor-total-pdf-orcamento-frete {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10px;
  color: rgb(63, 63, 63);
  margin-left: 3px;
  padding: 5px;
  border-radius: 5px;
  font-weight: 700;
}


.valores-container-pdf-orcamento {

  width: 100%;
  min-height: 40px;
  display: flex;
  font-size: 12px;
  padding: 2px;
  justify-content: flex-end;
  align-items: center;
}



.valor-total-pdf-orcamento {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: rgb(63, 63, 63);
  background-color: rgb(255, 0, 128);
  margin-left: 3px;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-weight: 700;
}

.peso-total-pdf-orcamento {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: rgb(63, 63, 63);
  background-color: rgb(170, 170, 170);
  margin-left: 10px;
  padding: 5px;

  border-radius: 5px;
  color: white;
  font-weight: 700;
}


/*------ TABLE ---------- */

.orcamento-table-pdf {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  color: #363636;
}

.orcamento-table-pdf th, .orcamento-table-pdf td {
  border: 1px solid #ddd;
  padding: 3px;
  font-size: 9px;
  text-align: center;
}

.orcamento-table-pdf th {
  background-color: #f2f2f2;
}

.tabela-lista-orcamento-pdf:hover {
  background-color: #f5f5f5;
}

.tabela-lista-orcamento-pdf td, .tabela-lista-orcamento-pdf th {
  cursor: pointer;
  font-size: 8px;
  color: #363636;
}

.info-date-version-orcamento {
  font-size: 8px;
}

.recalculo-orcamento-label {
  color: deeppink;
  margin-left: 10px;
}

.btn-delete-orcamento {
  border: none;
  background-color: transparent;
  font-size: 50px;
  width: 55px;
  height: 55px;
  position: fixed;
  bottom: 20px;
  left: 10px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-delete-orcamento:hover {
  background-color:deeppink;
color: white;
border-radius: 50px;

}


