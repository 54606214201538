/* BOARD */
.atividades-container {
    background-color: #0b99f894;
    backdrop-filter: blur(5px);
    width: 400px;
    max-width: 96vw;
    min-height: 400px;
    max-height: calc(100vh - 180px);
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
    overflow: hidden;
}

.atividades-header {
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.atividades-header .title {
    font-size: 16px;
    font-weight: 700;
    color: white;
}


.atividades-body {
    width: 100%;
    min-height: calc(400px - 40px - 30px);
    max-height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    padding-inline: 10px;
    overflow-y: auto;
}

.atividades-body .item-atividades {
    width: 100%;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 5px;
}

.atividades-pontos {
    margin-left: 10px;
}

.atividades-body .item-atividades:hover {
    background-color: deepskyblue;
    color: white;
}

.atividades-footer {
    width: 100%;
    height: 30px;
}

