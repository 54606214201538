.modal-edit-card-container {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-card-container {
  background-color: white;
  width: 1000px;
  max-width: 99%;
  max-height: calc(100% - 30px);
  border-radius: 10px;
  padding: 3px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 10px;
  z-index: 10;
}

.edit-card-header {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  display: flex;
}

.edit-card-header .title-header-edit-card {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  font-size: 20px;
  font-weight: 700;
}

.edit-card-header .btn-close-container {
  min-height: 50px;
  max-height: 50px;
  border-radius: 30px;
  display: flex;
  align-items: flex-start;
  padding: 5px;
}

.edit-card-header .btn-close {
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  border-radius: 30px;
  border: none;
  font-size: 14px;
}

.edit-card-header .btn-close:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}

.edit-card-body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-inline: 10px;
  overflow-y: auto;
  overflow-x: hidden;
}

.edit-card-body .title {
  width: 100%;
  min-height: 22px;
  display: flex;
  flex-direction: column;
  margin-inline: 5px;
}

.edit-card-body .input-edit-card {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.edit-card-body .input-edit-card:focus {
  border: 2px solid dodgerblue;
}


.fone-container {
  width: 100%;
  display: flex;
}

.icons-whatsapp {
  width: 38px;
  height: 38px;
  background-color: #00E676;
  color: white;
  border-radius: 5px;
  margin-left: 5px;
  padding: 2px;
}

.icons-whatsapp:hover {
  background-color: #00A884;
}

.icons-email {
  width: 38px;
  height: 38px;
  background-color: #ff2d2d;
  color: white;
  border-radius: 5px;
  margin-left: 5px;
  padding: 3px;
}

.icons-email:hover {
  background-color: #ff5e5e;
}


.edit-card-body .input-edit-card-info {
  background-color: #ffffff;
  width: 100%;
  height: 100px;
  min-height: 100px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.edit-card-footer {
  width: 100%;
  min-height: 60px;
  max-height: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-inline: 10px;
  position: sticky;
  bottom: 0;
  flex-direction: column;
}

.edit-card-footer-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.edit-card-footer .footer-rigth {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.edit-card-footer .btn-excluir {
  width: 80px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent;
  border: none;
  color: #757575;
}

.edit-card-footer .btn-excluir:hover {
  background-color: rgb(255, 74, 144);
  color: white;
}

.edit-card-footer .btn-arquivar {
  width: 80px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
}


.edit-card-footer .btn-cancel {
  background-color: rgb(179, 179, 179);
  width: 100px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.edit-card-footer .btn-cancel:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}

.edit-card-footer .btn-save {
  background-color: dodgerblue;
  width: 100px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  color: white;
  font-weight: 700;
}

.edit-card-footer .btn-save:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}


/* --- SELECT ---- */

.edit-card-select {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: #757575;
  padding-left: 10px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.card-select {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  color: #757575;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  border: 2px solid dodgerblue;
}

.card-select .item-list-select {
  width: 100%;
  min-height: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.card-select .item-list-select:hover {
  background-color: #B3B3B3;
  border-radius: 5px;
  color: rgb(255, 255, 255);
}


.pontencial-venda-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 15px;
  margin-bottom: 5px;
}

.pontencial-venda-container .title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-bottom: 15px;
}

.icon-star-edit-card {
  width: 24px;
  height: 24px;
  margin-inline: 2px;
  color: rgba(172, 172, 172, 0.952);
}

.funil-vendas {
  background-color: aqua;
  width: 200px;
  height: 50px;
  display: flex;
}


.define-process {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 0px;
}

.define-process .process {
  min-width: 140px;
  max-width: 120px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  margin-inline: 3px;
  font-size: 12px;
  padding: 2px;
  margin-bottom: 5px;
}

.define-process .process:hover {
  background-color: deeppink;
  color: white;
}

.define-process .process .icon-vendido {
  width: 20px;
  min-height: 20px;
  margin-right: 5px;
}

.define-process .process .icon-perdido {
  /*color:deeppink;*/

  width: 20px;
  min-height: 20px;
  margin-right: 5px;
}


/* ---- MODAL FINALIZADOS -----*/

.modal-cards-finalizados {
  width: 100vw;
  height: 100vh;
  background-color: rgb(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-finalizados-container {
  background-color: white;
  width: 600px;
  max-width: 97%;
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}

.btn-close-finalizados-container {
  width: 600px;
  max-width: 97%;
  padding: 5px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 50px;
}

.btn-close-finalizados {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: none;
  font-size: 18px;
}

.btn-close-finalizados:hover {
  background-color: deeppink;
  color: white;
}


@media (max-width: 600px) {
  .modal-finalizados-container {
    width: 100%;
    max-width: 97%;
  }

}

.icon-finalizados-vendido {
  color: rgb(47, 224, 255);
  font-size: 50px;
}

.icon-finalizados-perdido {
  color: rgb(255, 47, 134);
  font-size: 50px;
}

.icon-finalizados-entregue {
  color: #9862FF;
  font-size: 50px;
}

.icon-finalizados-ext {
  color: #ffc862;
  font-size: 50px;
}

.finalizados-container-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 160px;
  border-radius: 10px;
}

.finalizados-container-item:hover {
  background-color: #e0e0e0;
}


/* --------*/

.infos-finais-container {
  display: flex;
  width: 100%;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.label-data-modification {
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-inline: 10px;
}

/* ----- */

.icon-venda-efetuada-edit-card {
  color: dodgerblue;
  width: 40px;
  height: 40px;
  margin-left: 35px;
  margin-bottom: 5px;
}

.icon-venda-perdida-edit-card {
  color: deeppink;
  width: 40px;
  height: 40px;
  margin-left: 35px;
  margin-bottom: 5px;
}


.icon-venda-arquivada-edit-card {
  color: rgb(158, 158, 158);
  width: 40px;
  height: 40px;
  margin-left: 35px;
  margin-bottom: 5px;
}

/*------data picker ------*/

.container-producao {
  display: flex;
  align-items: flex-end;
}

.btn-clear-date-prod {
  width: 38px;
  height:38px;
  margin-bottom: 10px;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  color:white;
  background-color: deeppink;
}

.container-conclusao-producao {
  width: 100%;
}

.date-container {
  width: 100%;
  margin-right: 5px;
}

.input-data-picker-edit-card {
  background-color: #ffffff;
  width: 100%;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.input-data-picker-edit-card:focus {
  border: 2px solid dodgerblue;
}

.custom-calendar-edit-card {
  font-size: 18px;
}

.label-motivo-perca {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.anexos-container {
  background-color: #dfdfdf;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.title-anexos {
  padding-inline: 10px;
  font-size: 18px;
  font-weight: 700;
  min-height: 20px;
  margin-bottom: 5px;
}

.input-anexos {
  width: 100%;
  padding-inline: 5px;
  margin-bottom: 18px;
}

.arquivos-storage-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.item-arquivo {
  display: flex;
}

.link-anexo:hover {
  background-color: deepskyblue;
}

.link-anexo {
  color: white;
  margin-right: 1px;
  width: 100%;
  min-height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: deeppink;
  margin-bottom: 3px;
  border-radius: 5px;
  font-size: 13px;
  margin-left: 5px;
  text-decoration: none;
  padding-inline: 5px;
}

.info-upload-arquivo {
  color: red;
  font-size: 12px;
  width: 100%;
  margin-left: 8px;
  margin-bottom: 5px;
}

.btn-delete-anexo {
  border: none;
  min-width: 25px;
  min-height: 25px;
  max-width: 25px;
  max-height: 25px;
  background-color: #858585;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-right: 5px;
}

.btn-delete-anexo:hover {
  background-color: deepskyblue;
}

.limite-tamanho-arquivo {
  font-size: 14px;
}

/* ------------  MODAL COMFIRMAÇÃO -------------*/
.modal-confirm-container {
  background-color: rgba(0, 0, 0, 0.301);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-confirm {
  background-color: white;
  width: 400px;
  max-width: 97vw;
  height: 200px;
  display: flex;
  border-radius: 10px;
  flex-direction: column;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.label-mensagem-confirm {
  width: 100%;
  color: #ff5e5e;
  font-size: 16px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btns-modal-confirm-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-cancel-confirm {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background-color: deeppink;
  color: white;
}

.btn-cancel-confirm:hover {
  background-color: rgb(73, 73, 73);
}


.btn-save-confirm {
  width: 100px;
  height: 40px;
  border: none;
  border-radius: 8px;
  background-color: deepskyblue;
  color: white;
  margin-left: 50px;
}

.btn-save-confirm:hover {
  background-color: rgb(73, 73, 73);
}

/* ------------  MODAL AVISO -------------*/
.modal-mensagem-aviso-container {
  background-color: rgba(0, 0, 0, 0.301);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-mensagem-aviso {
  background-color: rgba(0, 0, 0, 0.521);
  width: 400px;
  max-width: 97vw;
  height: 200px;
  display: flex;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.label-mensagem-aviso {
  width: 100%;
  color: #fdfdfd;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ---- MODAL FINALIZADOS -----*/

.modal-cards-finalizados-edit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-finalizados-container-edit {
  background-color: white;
  width: 100%;
  height: calc(100px);
  border-radius: 10px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

.modal-finalizados-container-edit .title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.finalizados-container-icons-edit {
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon-finalizados-vendido-edit {
  color: rgb(47, 224, 255);
  font-size: 35px;
}

.icon-finalizados-perdido-edit {
  color: rgb(255, 47, 134);
  font-size: 35px;
}

.icon-finalizados-entregue-edit {
  color: #9862FF;
  font-size: 35px;
}

.icon-finalizados-ext-edit {
  color: #ffc862;
  font-size: 35px;
}

.finalizados-container-item-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 10px;
  margin-right: 10px;
}

.finalizados-container-item-edit:hover {
  background-color: #e0e0e0;
}

.finalizados-container-item-edit .title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

/* --------*/

.btn-duplicar-card-container {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.btn-duplicar-card-container button {
  width: 200px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
}

.btn-duplicar-card-container button:hover {
  background-color: deeppink;
  color: white;
}

.producao-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.anexos-header-container {
  background-color: #dfdfdf;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
}

.anexos-header {
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}