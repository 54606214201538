/* BOARD */
.processo-container {
    background-color: #0b99f894;
    backdrop-filter: blur(5px);
    width: 400px;
    max-width: 96vw;
    min-height: 400px;
    max-height: calc(100vh - 180px);
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
    overflow: hidden;
}

.processo-header {
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.processo-header .title {
    font-size: 16px;
    font-weight: 700;
    color: white;
}


.processo-body {
    width: 100%;
    min-height: calc(400px - 40px - 30px);
    max-height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    padding-inline: 10px;
    overflow-y: auto;
}

.processo-body .item-processo {
    width: 100%;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 5px;
}

.processo-body .item-processo:hover {
    background-color: deepskyblue;
    color: white;
}

.processo-footer {
    width: 100%;
    height: 30px;
}

.btn-create-processo {
    padding-inline: 3px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 15px;
    border: none;
    background-color: deeppink;
    border-radius: 30px;
    color: white;
    font-size: 35px;
    position: fixed;
    right: 15px;
    bottom: 25px;
    z-index: 8;
}

.btn-create-processo:hover {
    background-color: dodgerblue;
}

/* ----- modal novo processo ----*/

.modal-novo-processo-container {
    width: 100vw;
    height: 100vh;
    background-color: #0000001f;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
}

.modal-novo-processo {
    background-color: #0b99f894;
    backdrop-filter: blur(5px);
    width: 450px;
    max-width: 96%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 10px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.novo-processo-header {
    width: 100%;    
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 5px;
}

.novo-processo-header .title {
    margin-left: 10px;
    color: white;
    font-weight: 700;
    font-size: 18px;
}

.btn-close-novo-processo {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    border: none;
    color: white;
    background-color: rgb(110, 110, 110);
    font-size: 18px;
}

.btn-close-novo-processo:hover {
    background-color: deeppink;
}

.novo-processo-body {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 8px;
}

.input-novo-processo {
    background-color: #ffffff;
    width: 100%;
    height: 40px;
    min-height: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    color: rgb(70, 70, 70);
    padding-left: 10px;
}

.input-novo-processo:focus {
    border: 2px solid dodgerblue;
}

.novo-processo-footer {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 5px;
}

.btn-save-novo-processo {
    padding-inline: 10px;
    height: 40px;
    border: none;
    color: white;
    background-color: deeppink;
    border-radius: 5px;
    font-size: 16px;
}

.btn-save-novo-processo:hover {
    background-color: rgb(97, 97, 97);
}

.btn-excluir-novo-processo {
    padding-inline: 10px;
    height: 40px;
    border: none;
    color: white;
    background-color: transparent;
    border-radius: 5px;
}

.btn-excluir-novo-processo:hover {
    background-color: rgb(97, 97, 97);
}
