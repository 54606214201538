

.infos-gerais-container {
  width: calc((  100vw - 100px ) / 4 );
  min-width: calc((  100vw - 100px ) / 4 );
  max-width: 95vw;
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  background-color: rgba(219, 219, 219, 0.233);
  margin-bottom: 5px;
  overflow: hidden;
  padding: 10px;
  align-items: center;
}

.icon-infos-gerais-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.icon-infos-gerais{
  width: 42px;
  height: 42px;
}

.icon-infos-gerais-container {
  min-width: 25%;
  height: 80px;
}

.info-column-container {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.top-sellers {
  background-color: #20a6ff;
  width: calc(100%);
  border-radius: 10px;
  display: flex;
  color: white;
  margin: 1px;
  min-height: 80px;
  max-height: 80px;
}

.top-products {
  background-color: #ffc94c;
  width: calc(100%);
  border-radius: 10px;
  display: flex;
  color: white;
  margin: 1px;
  min-height: 80px;
  max-height: 80px;
}

.top-loss-reasons {
  background-color: #fd5176;
  width: calc(100%);
  border-radius: 10px;
  display: flex;
  color: white;
  margin: 1px;
  min-height: 80px;
  max-height: 80px;
}


.top-loss-reasonsxxx {
  background-color: #c849efb2;
  width: calc(100%);
  border-radius: 10px;
  display: flex;
  color: white;
  margin: 1px;
  min-height: 80px;
  max-height: 80px;
}

.infos-gerais-header-header{
  width: 100%;
  height: 30px;
  min-height: 25px;
  max-height: 25px;
  display: flex;
  align-items: center;
  justify-content:center ;
  font-size: 14px;
}

.infos-gerais-body {
  width: 100%;
  height: 50px;
  display: flex;
  flex-wrap: wrap;
  font-size: 11px;
}

.infos-gerais-item{
  width: 100%;
  min-height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .infos-gerais-container {
    width: 95vw;
  }

}

@media screen and (max-width: 1300px) {
  .icon-infos-gerais{
    width: 30px;
    height: 30px;
  }

  .icon-infos-gerais-container {
    width: 30px;
    height: 80px;
    display: flex;
    justify-content: center;
  }

  .infos-gerais-header-header{
    font-size: 12px;
  }
  
  .infos-gerais-body {
    font-size: 9px;
  }

}

