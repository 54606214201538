.grafico-container-c {
  width: calc((100vw - 5px) / 2.8);
  min-width: calc((100vw - 5px) / 2.8);
  max-width: 95vw;
  height: calc(100vh - 82px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(219, 219, 219, 0.233);
  margin-inline: 5px;
  flex-direction: column;
}

.grafico-cc {
  width: calc((  100vw - 5px ) / 2.8 );
  min-width: calc((100vw - 5px) / 2.8);
  max-width: 95vw;
  height: calc(100vh - 82px - 40px);
  display: flex;
}

@media screen and (max-width: 1100px) {
  .grafico-container-c {
    width: 95vw;
  }
  
  .grafico-cc {
    width: 95vw;
  }
}