/* BOARD */
.orcamentos-container {
  background-color: white;
  max-width: 100vw;
  height: calc(100vh - 40px);
  display: flex;
  flex-wrap: wrap;


}

.orcamentos-header {
  width: 100vw;
  height: 35px;
  display: flex;
  align-items: center;
  padding-inline: 15px;
}

.btn-create-orcamentos {
  background-color: rgb(255, 20, 145);
  width: 55px;
  height: 55px;
  cursor: pointer;
  color: white;
  border-radius: 30px;
  position: fixed;
  right: 15px;
  bottom: 25px;
  border: none;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-create-orcamentos:hover {
  background-color: dodgerblue;
  color: white;
}

.orcamentos-body {
  width: 100vw;
  max-width: 100vw;
  height: calc(100vh - 75px);
  display: flex;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
}


@media (max-width: 1490px) {
  .orcamentos-body {
    justify-content: flex-start;
  }
}

.input-create-orcamento {
  background-color: #ffffff;
  height: 38px;
  min-height: 38px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  color: rgb(70, 70, 70);
  padding-left: 10px;
  margin-bottom: 10px;
}

.input-create-orcamento:focus {
  border: 2px solid dodgerblue;
}

.new-orcamento-container {
  position: fixed;
  top: 50px;
  left: calc((100vw / 2) - 400px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 800px;
  max-height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 10px;
  padding: 10px;
}

.new-orcamento-container .title {
  min-height: 22px;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
}

.orcamento-select {

  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  color: #757575;
  margin-bottom: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: 2px solid dodgerblue;
}

.item-list-select-orcamento {
  width: 100%;
  min-height: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.item-list-select-orcamento:hover {
  background-color: #B3B3B3;
  border-radius: 5px;
  color: white;
}


.create-orcamento-footer {
  width: 100%;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 30px;
  margin-bottom: 10px;
  padding-inline: 10px;
  position: sticky;
  bottom: 0;
}

.create-orcamento-footer .btn-cancel-orcamento {
  background-color: rgb(179, 179, 179);
  width: 100px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
}

.create-orcamento-footer .btn-cancel-orcamento:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}

.create-orcamento-footer .btn-save-orcamento {
  background-color: dodgerblue;
  width: 100px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 10px;
  border: none;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  color: white;
  font-weight: 700;
}

.create-orcamento-footer .btn-save-orcamento:hover {
  background-color: rgb(252, 34, 118);
  color: white;
}




/*----------- LISTA DE ORÇAMENTOS ------------- */

.container-orcamentos {

  z-index: 0;

}


.container-orcamentos-principal {

  z-index: 0;
}

.lista-orcamentos-container {
  position: fixed;
  top: 115px;
  margin: 10px;
  width: calc(100vw - 20px);
  display: flex;
  flex-wrap: wrap;
  z-index: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: clacl(100% - 20px);
  background-color: rgb(252, 252, 252);
  padding: 10px;
  border-radius: 8px;
  overflow-x: auto;
  height: calc(100vh - 140px);
}

.item-list-orcamentos {
  width: 100%;
  height: 35px;
  display: flex;
  align-items: center;
  background-color: #e7e7e7;
  margin: 5px;
  justify-content: space-around;
}

.item-list-orcamentos:hover {
  background-color: deepskyblue;
  color: white;
}

.table-orcamentos {
  width: 100%;
}

.tabela-lista-orcamentos-header {
  width: 100%;

}

.item-table-orcamento-item {
  text-align: center;
  max-height: 40px;
  height: 40px;
  
}

.item-header-table-orcamentos {
  height: 40px;
  color: white;
}

.item-table-orcamento-item-header {

  background-color: rgb(0, 189, 247);
  border: 1px solid #ccc; /* Cor e largura da borda desejadas */
}

.item-table-orcamentos {
  width: 100%;
}


.linha-cinza-claro {
  background-color: #f5f5f5;
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  /* ou qualquer outra cor cinza claro desejada */
}

.linha-cinza-claro:hover {
  background-color: rgb(126, 126, 126);
  color: white;
  /* ou qualquer outra cor cinza claro desejada */
}

.linha-branca {
  height: 40px;
  max-height: 40px;
  min-height: 40px;
  background-color: #ffffff;
  /* ou qualquer outra cor branca desejada */
}

.linha-branca:hover {
  background-color: rgb(126, 126, 126);
  color: white;
  /* ou qualquer outra cor cinza claro desejada */
}

.tabela-lista-orcamentos-header,
.item-header-table-orcamentos,
.item-table-orcamento-item {
    border: 1px solid #ccc; /* Cor e largura da borda desejadas */
}

.table-orcamentos {
  width: 100%;
  border-collapse: collapse; /* Para garantir que as bordas das células se unam */
  height:max-content;
}

.table-body {
  overflow-y: auto; /* Adiciona rolagem vertical ao tbody */

} 

.ferramentas-lista-orcamentos-tools {
  width: calc(100% - 20px);
  height: 45px;
  display: flex;
  background-color: white;
  position: fixed;
  top: 65px;
  left: 0;
  margin-left: 10px;
  border-radius: 8px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 10px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  align-items: center;
}

.input-search-orcamento {
  width: 250px;
  height: 35px;
  border: 1px solid rgb(0, 162, 255);
  border-radius: 6px;
  padding-left: 8px;
  margin-left: 5px;
  color: white;
}

.input-search-orcamento:hover {
  box-shadow: rgba(1, 217, 255, 0.356) 2px 2px 2px 0px,rgba(0, 183, 255, 0.397) 0px 3px 7px -3px;
}

.btns-filter-orcamentos {
  padding-inline: 8px;
  height: 35px;
  margin-left: 5px;
  border: none;
  border-radius: 6px;
  background-color: #757575;
  color: white;
}

.btns-filter-orcamentos:hover {
  background-color: deepskyblue;

}

.title-filter-orcamentos-table {
  margin-left: 8px;
  font-size: 12px;
}


  