/* BOARD */
.produtos-container {
    background-color: #0b99f894;
    backdrop-filter: blur(5px);
    width: calc(100% - 50px);
    max-width: 99vw;
    min-height: 400px;
    max-height: calc(100vh - 180px);
    display: flex;
    flex-wrap: wrap;
    border-radius: 10px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 140px;
    overflow: hidden;

}

.item-lista-produtos-adm-codigo {
    min-width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(99, 99, 99);
    margin-right: 10px;
    color: white;
    border-radius: 5px;
    height: 35px;
}

.item-lista-produtos-adm {
    width: 100%;
    min-width: 400px;
    padding-inline: 5px;
}

.item-lista-produtos-cor {
    width: 100%;
    padding-inline: 5px;
    height: 35px;
    display: flex;
    align-items: center;
    border-radius: 5px;
}

.produtos-header {
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.produtos-header-buscar {
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 5px;
    margin-bottom: 5px;
}

.title-filter-produto-table {
    color: white;
    margin-left: 5px;
}

.produtos-header .title {
    font-size: 16px;
    font-weight: 700;
    color: white;
}


.produtos-body {
    width: 100%;
    min-height: calc(400px - 40px - 30px);
    max-height: calc(100vh - 280px);
    display: flex;
    flex-direction: column;
    padding-inline: 10px;
    overflow-y: auto;
}

.produtos-body .item-produto {
    width: 100%;
    min-height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 5px;
    margin-bottom: 5px;
}

.produtos-body .item-produto:hover {
    background-color: deepskyblue;
    color: white;
}

.produtos-footer {
    width: 100%;
    height: 30px;
}

.btn-create-produto {
    padding-inline: 3px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 15px;
    border: none;
    background-color: deeppink;
    border-radius: 30px;
    color: white;
    font-size: 35px;
    position: fixed;
    right: 15px;
    bottom: 25px;
    z-index: 8;
}

.btn-create-produto:hover {
    background-color: dodgerblue;
}

/* ----- modal novo produto ----*/

.modal-novo-produto-container {
    width: 100vw;
    height: 100vh;
    background-color: #0000001f;
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    display: flex;
    justify-content: center;
}

.modal-novo-produto {
    background-color: #0b99f894;
    backdrop-filter: blur(5px);
    width: 600px;
    max-width: 99%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    border-radius: 10px;
    position: fixed;
    top: 55px;
    overflow-y: auto;
    max-height: calc(100vh - 80px);
}

.novo-produto-header {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 5px;
}

.novo-produto-header .title {
    margin-left: 10px;
    color: white;
    font-weight: 700;
    font-size: 20px;
}

.btn-close-novo-produto {
    width: 35px;
    height: 35px;
    border-radius: 20px;
    border: none;
    color: white;
    background-color: rgb(110, 110, 110);
    font-size: 18px;
}

.btn-close-novo-produto:hover {
    background-color: deeppink;
}

.novo-produto-body {
    width: 100%;    
    display: flex;
    align-items: center;
    padding: 8px;
}

.input-novo-roduto {
    background-color: #ffffff;
    width: 100%;
    height: 40px;
    min-height: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    color: rgb(70, 70, 70);
    padding-left: 10px;
}

.input-novo-roduto:focus {
    border: 2px solid rgb(255, 30, 169);
}

.novo-produto-footer {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 5px;
}

.btn-save-novo-produto {
    padding-inline: 10px;
    height: 40px;
    border: none;
    color: white;
    background-color: deeppink;
    border-radius: 5px;
    font-size: 16px;
}

.btn-save-novo-produto:hover {
    background-color: rgb(97, 97, 97);
}

.btn-excluir-novo-produto {
    padding-inline: 10px;
    height: 40px;
    border: none;
    color: white;
    background-color: transparent;
    border-radius: 5px;
}

.btn-excluir-novo-produto:hover {
    background-color: rgb(97, 97, 97);
}






/* --- SELECT ---- */

.edit-produto-select {
    background-color: #ffffff;
    width: 100%;
    height: 38px;
    min-height: 38px;
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #B3B3B3;
    color: #757575;
    padding-left: 10px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .produto-select {
    background-color: #ffffff;
    width: calc(100% - 15px);
    border-radius: 5px;
    color: #757575;
    margin-bottom: 4px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 5px;
    border: 2px solid dodgerblue;
  }
  
  .produto-select .item-list-select-produto {
    width: 100%;
    min-height: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  
  .produto-select .item-list-select-produto:hover {
    background-color: #B3B3B3;
    border-radius: 5px;
    color: rgb(255, 255, 255);
  }