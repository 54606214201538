
/* BOARD */
.participante-container {
    width: 100vw;
    height: calc(100%);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.participante-header {
    width: 100vw;
    height: 35px;
    display: flex;
    align-items: center;
    padding-inline: 10px;
}

.participante-header .title {
    font-size: 20px;
    font-weight: 700;
}

.btn-create-empresa {
    padding-inline: 3px;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 15px;
    border: none;
    background-color: deeppink;
    border-radius: 30px;
    color: white;
    font-size: 35px;
    margin-left: 15px;
    position: fixed;
    right: 15px;
    bottom: 25px;
    z-index: 8;
}

.btn-create-empresa:hover {
    background-color: dodgerblue;
}

.participantes-body {
    min-width: 100vw;
    max-width: 100vw;
    height: calc(100vh - 100px);
    display: flex;
    justify-content:space-evenly;
    flex-wrap: wrap;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5px;
}

.lista-participantes-container {
    width: 600px;
    max-height: calc(100vh - 100px);
    margin-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.participantes-body .lista-participantes {
    width: 100%;
    max-height: calc(100vh - 220px);
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: center;
}

.participantes-body .item-participante {
    background-color:#b1b1b146;
    backdrop-filter: blur(5px);
    width: 800px;
    max-width: 95vw;
    min-height: 70px;
    max-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    margin-bottom: 8px;
    text-align: center;
}

.item-participante:hover {
    background-color:dodgerblue;
    color: white;
}

.header-lista {
    padding-inline: 20px;
    height: 70px;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
