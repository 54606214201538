.grafico-container-a {
  width: calc((  100vw - 100px ) / 4 );
  min-width: calc((  100vw - 100px ) / 4 );
  max-width: 95vw;
  height: calc( ( 100vh - 75px - 360px));
  /*height: calc(100vh - 82px);*/
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: rgba(219, 219, 219, 0.233);
  margin-bottom: 5px;
}

.grafico-aa {
  width: calc((  100vw - 70px ) / 4 );
  min-width: calc((  100vw - 100px ) / 4 );
  max-width: 95vw;
  height: calc( ( 100vh - 75px - 360px) );
  /*height: calc(100vh - 82px);*/
  display: flex;

}

@media screen and (max-width: 1100px) {
  .grafico-container-a {
    width: 95vw;
  }
  
  .grafico-aa {
    width: 95vw;
  }
}



